import collapsible from './modules/collapsible';
collapsible.init();

// import createScrollSnap from 'scroll-snap'



window.onload = function () {


    // remove loading class
    document.querySelectorAll('body')[0].classList.remove('loading');

    // Scroll animation
    const section = document.querySelectorAll('.scroll-container section');

    const observerOptions = {
        root: document.querySelectorAll('.scroll-container')[0],
        threshold: 0.25,
    };

    const observer = new IntersectionObserver(entries => {
        entries.forEach(function (entry: any) {
            if (entry.isIntersecting) {
                entry.target.classList.add('is-visible');

                if (entry.target.classList.contains('product-block') && entry.target.querySelectorAll('[aria-hidden="false"]').length <= 0) {

                    clearTimeout(timeout);
                    const smalltext = entry.target.querySelectorAll('.smalltext')[0];
                    timeout = setTimeout(() => {

                        smalltext.style.height = smalltext.scrollHeight + "px";
                        smalltext.setAttribute('aria-hidden', 'false');

                    }, 1300);

                }

            } else {

                if (!entry.target.classList.contains('product-block')) {
                    entry.target.classList.remove('is-visible');
                }

            }
        })
    }, observerOptions);

    section.forEach(function (section) {
        observer.observe(section);
    });



    // Team block

    const teamBlock: any = document.querySelectorAll('.team-block')[0];
    const teamMembers: any = teamBlock?.querySelectorAll('.member');

    teamMembers?.forEach(function (member) {

        member.querySelectorAll('.about')[0].style.height = '0';
        member.querySelectorAll('[data-member-image]')[0].addEventListener('click', function (e) {

            let activeMemmber = "." + member.querySelectorAll('[data-member-image]')[0].getAttribute('data-member-image');

            if (member.classList.contains('active')) {

                teamMembers.forEach(function (member) {
                    member.classList.remove('active');
                });
                member.classList.remove('active');
                setTeamBlockImage('.default');
                teamBlock.classList.add('default');

            } else {

                teamMembers.forEach(function (member) {
                    member.classList.remove('active');
                });
                member.classList.add('active');
                setTeamBlockImage(activeMemmber);
                teamBlock.classList.remove('default');
            }

        });
    });


    // Function to add class to .team-block with clearable timeout
    let timeout: any;

    function setTeamBlockImage(activeMemmber) {
        teamBlock.querySelectorAll('.image')[0].classList.add('change');
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            teamBlock.querySelectorAll('.image')[0].classList.remove('change');

            teamBlock.querySelectorAll('img').forEach(function (img) {
                img.classList.remove('active');
            });

            teamBlock.querySelectorAll(activeMemmber)[0]?.classList.add('active');

        }, 400);
    }




    // Carousel

    const carouselContainer: any = document.querySelectorAll('.carousel-contianer')[0];
    const caruselItems: any = document.querySelectorAll('.carousel-contianer .carousel-item');

    caruselItems?.forEach(function (item) {

        item.addEventListener('click', function (e) {
            carouselContainer.querySelectorAll('.active')[0].classList.remove('active');
            item.classList.add('active');
        });

    });

    caruselItems[0]?.classList.add('active');

    carouselContainer?.style.setProperty('--item-count', caruselItems.length + 1);






    // Hero block
    const rightColumn: any = document.querySelectorAll('.hero-block .right-column')[0];
    const supervisionLogo: any = document.querySelectorAll('.supervision-logo');

    let timeout3: any;


    function updateWindowSize() {


        supervisionLogo.forEach(element => {
            element.classList.add('resizing');
        });

        clearTimeout(timeout3);
        timeout3 = setTimeout(() => {
            supervisionLogo.forEach(element => {
                element.classList.remove('resizing');
            });
        }, 100);

        supervisionLogo.forEach((supervisionLogo: any) => {


            supervisionLogo.querySelectorAll('div').forEach((div: any) => {
                div.classList.remove('shrink');
            });


            supervisionLogo.querySelectorAll('div:nth-child(odd)').forEach((div: any) => {
                div.classList.add('shrink');
            });

            supervisionLogo.querySelectorAll('div').forEach((div: any) => {
                const originalWidth = div.querySelectorAll('img')[0].offsetWidth;
                const transformedWidth = originalWidth * 0.24;

                div.setAttribute('data-original-width', originalWidth);
                div.setAttribute('data-shrinked-width', transformedWidth);

            });

            let totalWidth = 0;

            supervisionLogo.querySelectorAll('div').forEach((div: any) => {
                if (div.classList.contains('shrink')) {
                    totalWidth += parseInt(div.getAttribute('data-shrinked-width'));
                } else {
                    totalWidth += parseInt(div.getAttribute('data-original-width'));
                }
            });

            animateLogo(supervisionLogo);

            let logoScale;

            if (window.innerWidth > 768) {

                logoScale = (window.innerWidth / 1.62) / supervisionLogo.clientWidth;

                if (logoScale > 1) {
                    logoScale = 1;
                }

                supervisionLogo.setAttribute('style', `transform: scaleX(${logoScale})`);

            } else {
                supervisionLogo.setAttribute('style', `transform: scale(1)`);
            }



        });

        supervisionLogo[1].querySelectorAll('div').forEach((div: any) => {

            if (div.classList.contains('shrink')) {
                div.setAttribute('style', `width: ${div.getAttribute('data-original-width')}px`);
                div.classList.remove('shrink');
            } else {
                div.setAttribute('style', `width: ${div.getAttribute('data-shrinked-width')}px`);
                div.classList.add('shrink');
            }

        });

    }





    function animateLogo(supervisionLogo) {

        supervisionLogo.querySelectorAll('div').forEach((div: any) => {

            if (div.classList.contains('shrink')) {
                div.setAttribute('style', `width: ${div.getAttribute('data-original-width')}px`);
                div.classList.remove('shrink');
            } else {
                div.setAttribute('style', `width: ${div.getAttribute('data-shrinked-width')}px`);
                div.classList.add('shrink');
            }

        });
    }




    if (rightColumn && supervisionLogo) {

        setInterval(() => {
            animateLogo(supervisionLogo[0]);
            animateLogo(supervisionLogo[1]);
        }, 2500);


        updateWindowSize();
        window.addEventListener("resize", updateWindowSize);
    }


};